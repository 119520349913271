<template>
  <Details
    title="Party"
    resource="parties"
    :base-path="basePath"
    data-provider="$raaDataProvider"
    route-id-param="partyId"
    :title-template-render="({ deletedAt }) => `${deletedAt ? 'view' : 'edit'} party`"
    :breadcrumb-template-render="({ deletedAt }) => deletedAt ? 'view' : 'edit'"
  >
    <template v-slot:side-panel="{ record }">
      <DetailsSidePanel entity-title="party" :view-mode="Boolean(record.deletedAt)"></DetailsSidePanel>
    </template>
    <template v-slot:default="detailsProps">
      <div>
        <div v-if="detailsProps.record.deletedAt" class="flex justify-end text-sm mb-4">
          {{detailsProps.getDeletedLabel(detailsProps.record.deletedAt)}}
        </div>
      </div>
      <PartyForm
        :initial-values="detailsProps.record"
        :on-submit="detailsProps.handleSubmit"
        :loading="detailsProps.loading"
        @close="detailsProps.redirectToList"
      />
    </template>
  </Details>
</template>

<script>
  import PartyForm from "@/views/auth/raa-devtools/parties/PartyForm";
  import Details from "@/components/auth/details/Details";
  import DetailsSidePanel from "@/components/auth/details/DetailsSidePanel";
  import ModalNavigation from "@/mixins/ModalNavigation";

  export default {
    name: "PartyEdit",
    mixins: [ModalNavigation],
    components: {
      PartyForm,
      Details,
      DetailsSidePanel,
    },
    computed: {
      basePath: function () {
        return this.$route.name.replace('edit', 'index');
      },
    }
  }
</script>

