<template>
  <Form
    ref="form"
    :submit="onSubmit"
    :initialValues="initialValues"
  >
    <div class="form-narrow">
      <div class="form-row">
        <TextField name="contactId" label="contact id*" :validate="composeValidators(required, uuid)" :editMode="editable" />
      </div>
      <div class="form-row">
        <TextField name="communityId" label="community id*" :validate="composeValidators(required, uuid)" :editMode="editable" />
      </div>
    </div>
    <ModalFooter :footer="footer" :tertiary="cancel" />
  </Form>
</template>

<script>
  import ModalNavigation from "@/mixins/ModalNavigation";
  import Form from "@/components/form/Form";
  import TextField from "@/components/form/TextField";
  import NotifyMixin from "@/mixins/NotifyMixin";
  import ValidatorMixin from "@/components/form/ValidatorMixin";
  import ModalFooter from "@/components/ui/modals/ModalFooter";

  export default {
    name: "PartyForm",
    mixins: [
      ModalNavigation,
      ValidatorMixin,
      NotifyMixin
    ],
    components: {
      Form,
      TextField,
      ModalFooter
    },
    props: {
      onSubmit: {
        type: Function,
        required: true,
      },
      initialValues: {
        type: Object,
        default: () => ({})
      },
      loading: {
        type: Boolean,
        required: false,
        default: false,
      },
    },
    data() {
      return {
        persons: [],
        typeOptions: [ // TODO: atm only 'person' type is supported. It's assumed to have 'company', 'employee' types in future
            { key: 'person', value: 'person' }
        ],
      }
    },
    computed: {
      editable() {
        return !this.initialValues.deletedAt;
      },
      footer() {
        if (this.editable) {
          return {
            primaryButton: 'save',
            tertiaryButton: 'cancel'
          };
        }

        return {
          tertiaryButton: 'back'
        };
      }
    },
    methods: {
      cancel(e) {
        e.preventDefault();
        this.close();
      },
    },
  }
</script>


